import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { ITEM_PROCESS } from '../../../utils'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import LocIcon from './LocIcon'
import Popup from './Popup'

export default function DeliveryStop({ stop, setMarkersRef }) {
    const { lat, lng, cmp, branch, orders } = stop
    const orderer = branch ? `${cmp} (${branch})` : `${cmp}`
    const [open, setOpen] = useState(false)
    const stopLabel = ITEM_PROCESS[stop?.process]||null

    const [markerRef, marker] = useAdvancedMarkerRef()
    useEffect(() => {
        if (marker) {
            setMarkersRef(marker, stop?.itemId)
        }
    }, [marker])

    if (!lat||!lng) return null
    return (
        <AdvancedMarker
            position={{ lat, lng }}
            onClick={() => setOpen(!open)}
            ref={markerRef}
            anchor={{ x: 0.5, y: 1 }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: 'fit-content',
                    border: 1,
                }}
            >
                <LocIcon stop={stop} />
                {open && (<Popup orderer={orderer} status={stopLabel||'-'} orders={orders} />)}
            </Box>
        </AdvancedMarker>
    )
}

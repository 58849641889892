import { Box, Button, DialogContent, Divider, ListItemText, Typography } from '@mui/material'
import { COMPANY_TYPES, scrollStyle } from '../../../utils/constants'
import AddressForm from '../RegisterAddress/AddressForm'
import { AppRegistration } from '@mui/icons-material'
import { getCompanyName } from '../../../api/auth'
import { addCompany } from '../../../api/company'
import { getToast } from '../helpers/ToastAlert'
import { useState, useEffect } from 'react'
import CompanyInfo from './CompanyInfo'
import GetLocation from './GetLocation'
import AddPassword from './AddPassword'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import LocationDialog from './LocationDialog'

const addressSchema = Yup.object({
    province: Yup.number().min(1).required('Аймаг/Хот сонгоно уу!').nullable(),
    district: Yup.number().min(1).required('Сум/Дүүрэг сонгоно уу!').nullable(),
    khoroo: Yup.number().min(1).required('Баг/Хороо сонгоно уу!').nullable(),
    detailed: Yup.string().nullable().min(1, 'Хаягийн дэлгэрэнгүй оруулна уу!').max(400, 'Хаягийн дэлгэрэнгүй 400 тэмдэгтээс урт байж болохгүй!').required('Хаягийн дэлгэрэнгүй оруулна уу!'),
}).nullable()

const address2Schema = Yup.object({
    lat: Yup.number()
        .required('Latitude is required')
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
    lng: Yup.number()
        .required('Longitude is required')
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180'),
    address2: Yup.string().nullable(),
}).nullable()

const validationSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Нэр хэт урт байна! 150 тэмдэгтээс бага байх ёстой!').required('Нэр оруулна уу!'),
    rd: Yup.string().matches(/^\d{7}$/, 'Регистр буруу байна!').required('Регистр оруулна уу!'),
    cType: Yup.string().oneOf(Object.keys(COMPANY_TYPES), 'Үйл ажиллагааны чиглэл буруу байна!').required('Үйл ажиллагааны чиглэл сонгоно уу!'),
    address: addressSchema,
    address2: address2Schema,
    note: Yup.string().max(255, 'Нэмэлт тайлбар хэт урт байна! 255 тэмдэгтээс бага байх ёстой!').required('Нэмэлт тайлбар оруулна уу!'),
    referral_code: Yup.string().min(6, 'Урилгын код 6 тэмдэгтээс бүрдсэн байх ёстой!').max(6, 'Урилгын код 6 тэмдэгтээс бүрдсэн байх ёстой!').nullable(),
})

export default function RegistrationForm({ alertSuccess, handleClose, email }) {
    const [error, setError] = useState(null)
    const handleErrOn = (err) => { setError(err) }
    const handleErrOff = () => { setError(null) }

    const [licenseFiles, setLicenseFiles] = useState([])
    const [logo, setLogo] = useState(null)
    const [formData, setFormData] = useState(null)
    const attachPwd = (password) => { setFormData(fd => ({ ...fd, password })) }
    const processFormData = () => {
        const { address, address2, ...rest } = formData
        const dt = new FormData()
        if (address) dt.append('address', JSON.stringify(address))
        if (address2) dt.append('address2', JSON.stringify(address2))
        dt.append('logo', logo)
        for (let l of licenseFiles) { dt.append('license', l) }
        for (const k in rest) { dt.append(k, rest[k]) }
        return dt
    }

    // pwd dialog
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClosePwdDialog = () => { setOpen(false) }
    const handleSend = () => {
        const fd = processFormData()
        addCompany(fd)
            .then(_ => { alertSuccess(); handleClose() })
            .catch(pe => {
                console.log("Comp err: ", pe)
                const dt = pe?.response?.data
                const rdErr = dt?.rd?.[0]?.includes('Company with this Регистрийн')
                if (rdErr) { handleErrOn("Регистрийн дугаар бүртгэлтэй байна!") }
                if (dt?.email) { handleErrOn(dt.email) }
                if (dt?.user) { handleErrOn(dt.user) }
                if (dt?.password) { handleErrOn(dt.password) }
                if (dt?.referral_code_error) { handleErrOn(dt.referral_code_error) }
                handleClosePwdDialog()
            })
    }

    const { handleSubmit, getFieldProps, setFieldValue, values, errors, touched } = useFormik({
        initialValues: {
            name: '',
            rd: '',
            cType: '',
            address: null,
            address2: null 
        },
        onSubmit: (vals) => {
            if (licenseFiles?.length > 0) {
                const { address, address2 } = vals
                const isAddressEmpty = !address || Object.values(address).every(v => v === undefined || v === null || v === '')
                const isAddress2Empty = !address2 || Object.values(address2).every(v => v === undefined || v === null || v === '')
                if (isAddressEmpty && isAddress2Empty) {
                    setError('Хаягийн мэдээлэл оруулна уу!')
                    return
                }
                setFormData({ ...vals, email })
                handleOpen()
                //add(data)
            } else { setError('Тусгай зөвшөөрлийн файл хавсаргана уу!') }
        },
        validationSchema,
        validateOnChange: false
    })

    useEffect(() => {
        if (values?.rd) {
            if (values.rd.length == 7 && /^\d{7}$/.test(values.rd)) {
                getCompanyName(values.rd).then(res => {
                    if (res?.data) {
                        setFieldValue('name', res?.data)
                    }
                }).catch(e => {
                    console.log("ERR: ", e)
                })
            } else { setFieldValue('name', '') }
        }
    }, [values?.rd])

    const setAddressFieldValue = (f, v) => { setFieldValue(`address.${f}`, v) }
    const setAddress2FieldValue = (v) => { setFieldValue(`address2`, v); }

    const getErrors = (name) => {
        const isErr = Object.keys(errors).includes(name) && Object.keys(touched).includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const handleKeyDown = (e) => {
        const n = e?.target?.id
        if (n === 'rd') {
            if (['ArrowRight', 'Enter'].includes(e.code)) {
                const nf = document.getElementById('name')
                if (nf) { nf.focus() }
            }
            if (e.code === 'ArrowDown') { document.getElementById('cType')?.focus() }
        }
        if (n === 'name' && e.code === 'ArrowLeft') { document.getElementById('rd')?.focus() }
    }

    const [isApiFailed, setIsApiFailed] = useState(false)
    const [isGettingAddress, setIsGettingAddress] = useState(false)

    return (
        <DialogContent 
            sx={{ 
                width: '700px', 
                p: 2, 
                maxHeight: '90vh',
                ...scrollStyle 
            }}
        >
            {getToast(Boolean(error), handleErrOff, error, 'warning' )}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                <AppRegistration sx={{ fontSize: '35px', color: 'secondary.light', mr: 1 }}/>
                <ListItemText
                    primary={<Typography fontSize='18px' fontStyle='italic' sx={{ color: 'secondary.light' }}>Бүртгэл гүйцээх</Typography>}
                    secondary={<Typography fontSize='14px' fontStyle='italic' sx={{ color: 'primary.light' }}>Мэдээллээ бүрэн бөглөхийг хүсье.</Typography>}
                />                
            </Box>
            <Box>
                <Divider textAlign='left' sx={{ mb: 2, mt: 3 }}>
                    <Typography sx={{ color: 'primary.light', fontSize: '14px', fontStyle: 'italic' }}>Байгууллагын мэдээлэл</Typography>
                </Divider>
                <CompanyInfo 
                    getErrors={getErrors} 
                    getFieldProps={getFieldProps}
                    licenseFiles={licenseFiles}
                    setLicenseFiles={setLicenseFiles}
                    logo={logo}
                    setLogo={setLogo}
                    setFieldValue={setFieldValue}
                    cType={values?.cType}
                    handleKeyDown={handleKeyDown}
                />
            </Box>
            <Box>
                <Divider textAlign='left' sx={{ mb: 1, mt: 0 }}>
                    <Typography sx={{ color: 'primary.light', fontSize: '14px', fontStyle: 'italic' }}>Хаягийн мэдээлэл</Typography>
                </Divider>
                {
                    isApiFailed && 
                        <AddressForm 
                            setFieldValue={setAddressFieldValue}
                            values={values?.address}
                            errors={errors?.address} 
                            touched={touched?.address}
                        />
                }
                <GetLocation
                    setAddressInfo={setAddress2FieldValue}
                    setIsApiFailed={setIsApiFailed}
                    setIsGettingAddress={setIsGettingAddress}
                />
                {/* <LocationDialog
                    addressInfo={values?.address2}
                    setAddressInfo={setAddress2FieldValue}
                    setIsApiFailed={setIsApiFailed}
                    setIsGettingAddress={setIsGettingAddress}
                /> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
                <Button
                    onClick={ handleSubmit }
                    size='small'
                    variant='contained'
                    sx={{ p: '4px', width: '150px' }}
                    disabled={isGettingAddress}
                >Баталгаажуулах</Button>
            </Box>
            {
                open &&
                    <AddPassword
                        handleClosePwdDialog={handleClosePwdDialog}
                        handleSend={handleSend}
                        attachPwd={attachPwd}
                        processFormData={processFormData}
                    />
            }
        </DialogContent>
    )
}

import { addBasketItem, removeFromBasket, updateItemQty } from "../api/basket"
import { checkProductQty } from "../api/product"
import { useAuthCtx } from "../context/AuthCtx"
import { useEffect, useRef, useState } from "react"

export const qtyMsgs = {
    added: 'Сагсанд амжилттай нэмэгдлээ!',
    removed: 'Сагснаас амжилттай хасагдлаа!',
    lessQty: 'Үлдэгдэл хүрэлцэхгүй байна.',
    noQty: 'Бараа дууссан байна!',
    tryErr: 'Түр хүлээгээд дахин оролдоно уу!'
}

export const useAddToCart = ({ product, qty = 1, isUpdate = false, inDetail = false, inOrderItems = null }) => {
    const { basket, fetchBasket, setAddAlert, setAlert } = useAuthCtx()
    const item = basket?.items?.find(b => b.product_id === product?.id)

    // isSuccess
    const [success, setSuccess] = useState(false) // сагсанд амжилттай нэмэгдсэн
    const [remSuccess, setRemSuccess] = useState(false) // сагснаас амжилттай хасагдсан
    const handleReset = () => { setSuccess(false); setRemSuccess(false) }

    const [alertMsg, setAlertMsg] = useState('')
    const alertOff = () => { handleReset(); setAlertMsg('') }

    // сагсанд нэмэх
    const add = () => {
        addBasketItem({ product: product.id, qty })
            .then(_ => { fetchBasket(); setAlertMsg(qtyMsgs.added); setSuccess(true) })
            .catch(err => { setAlertMsg(qtyMsgs.tryErr); console.log("ERR: ", err) })
    }
    // сагснаас хасах
    const remove = () => {
        removeFromBasket(item?.id)
            .then(_ => { fetchBasket(); setAlertMsg(qtyMsgs.removed); setRemSuccess(true); })
            .catch(_ => { setAlertMsg(qtyMsgs.tryErr) })
    }
    // тоо, ширхэг өөрчлөх
    const update = () => {
        updateItemQty(item?.id, qty)
            .then(_ => {
                fetchBasket(); setSuccess(true)
                setAlertMsg(qtyMsgs.added)
            })
            .catch(_ => { setAlertMsg(qtyMsgs.tryErr) })
    }

    // үлдэгдэл шалгаад сагсанд нэмэх/хасах/шинэчлэх
    const isIncluded = basket?.items?.length ? basket.items.map(p => p?.product_id)?.includes(product?.id) : false
    const id = product?.itemname_id ? product.itemname_id : product?.id
    const qtys = { [id]: qty }
    //console.log("I: ", id, product?.itemname_id)
    const handleAddItem = () => {
        checkProductQty(qtys)
            .then(r => {
                const l = r?.data?.[id]
                if (l === null) { setAlertMsg(qtyMsgs.noQty); }
                else if (l === false) { setAlertMsg(qtyMsgs.lessQty); }
                else {
                    isIncluded
                        ?   isUpdate
                                ?   update()
                                :   remove()
                        :   add()
                }
            })
            .catch(e => { setAlertMsg(qtyMsgs.tryErr); console.log("checkQtyERr: ", e) })
    }

    const isSuccess = success || remSuccess
    const msgClr = success ? 'success' : remSuccess ? 'info' : 'warning'
    // alertMsg-г authCtx-р дамжуулан нүүр хуудасны Results component дээр харуулах
    useEffect(() => {
        if (alertMsg && !inDetail && !inOrderItems) { setAddAlert({ alertMsg, msgClr, alertOff }); setAlert(true) }
        if (!alertMsg) { setAddAlert(null); setAlert(false) }
    }, [alertMsg])
    return [ handleAddItem, alertMsg, alertOff, isSuccess, isIncluded, msgClr ]
}

// Count Input
export const useCountInput = ({ setCount, handleClick = () => {}, count = 1 }) => {
    const [err, setErr] = useState(null)
    const [value, setValue] = useState(count)

    const handleValue = (e) => {
        const newVal = e?.target?.value
        setValue(newVal)
        if (!newVal) { setErr('Утга оруулна уу!') }
        else if (isNaN(newVal)) { setErr('Тоон утга оруулна уу!') }
        else if (newVal <= 0) { setErr('1-с багагүй утга оруулна уу!') }
        else { setCount(newVal); setErr(null) }
    }

    const handleAdd = () => { if (count && !err) { handleClick() } }
    const handleEnter = (e) => { if (e.code === 'Enter' && count && !err) { handleClick() } }

    return { err, value, handleValue, handleAdd, handleEnter }
}

// Table Pagination
export const useTablePagination = (rppOptions) => {
    const [page, setPage] = useState(1)
    const handlePageChange = (e, newPage) => { setPage(newPage) }
    const [pageSize, setPageSize] = useState(rppOptions[0])
    const handlePageSizeChange = (e) => {
        setPage(1)
        setPageSize(parseInt(e.target.value))
    }
    return [ page, handlePageChange, pageSize, handlePageSizeChange, rppOptions ]
}

// get external component's rect
export const useExternalComponentRect = ({ externalRef }) => {
    const [rect, setRect] = useState(null)
    const resizeObserver = useRef(null)
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            setRect(entries[0].contentRect)
        })

        if (externalRef.current) { resizeObserver.current.observe(externalRef.current) }
        return () => { if (resizeObserver.current) { resizeObserver.current.disconnect() } }
    }, [externalRef])
    return rect||{}
}


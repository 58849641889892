import { Checkbox, IconButton, TableCell, TableRow, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { createElement, useCallback, useEffect, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { toPrice } from '../../../../../../utils/functions'
import SelectProcessCell from './Cells/SelectProcessCell'
import { PAYMENT_TYPES } from '../../../../../constants'
import SelectStatusCell from './Cells/SelectStatusCell'
import SelectStaffCell from './Cells/SelectStaffCell'
import { BranchCell } from './Cells/BranchCell'
import SellerCell from './Cells/SellerCell'
import { useOrderListCtx } from '../..'
import NoteCell from './Cells/NoteCell'
import OrderDetail from './OrderDetail'

const cellWidthMap = {
    orderNo: '80px',
    createdOn: '130px',
    payType: '80px',
    totalPrice: '100px',
}
const CreatedOnCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.light
}))

export default function Row({ initialRow, idx, fieldsInfo, expandedRow, setExpandedRow, refetch, colsLength }) {
    const { role, sels, handleSelect, page, pageSize } = useOrderListCtx()

    const [row, setRow] = useState(initialRow)
    useEffect(() => { setRow(initialRow) }, [initialRow])
    const inSels = sels?.includes(row?.id)
    //const [orderDetail, setOrderDetail] = useState(null)
    //const imported = orderDetail && orderDetail?.$$typeof === Symbol.for('react.element')
    /* const odProps = { orderId: row?.id, row, setRow, colsLength }
    const loadOrderDetail = useCallback(async () => {
        const module = await import ('./OrderDetail')
        setOrderDetail(createElement(module.default, odProps))
    }, [row]) */
    const [open, setOpen] = useState(expandedRow === row?.id)
    const handleOpen = () => {
        setOpen(!open);
        if (!open) { setExpandedRow(row?.id) }
    }
    //useEffect(() => { open ? loadOrderDetail() : setOrderDetail(null) }, [open])

    const checkDisable = ['C', 'S'].includes(row.status) || ['A', 'O'].includes(row.process)

    //console.log("ROW: ", initialRow, expandedRow)

    return (
        <>
            <TableRow
                key={`${idx + 1}`}
                sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 }, maxHeight: '20px', 
                    '& > td': { p: '2px', fontSize: '13px', textAlign: 'center' }, 
                    '&:hover': { backgroundColor: '#f1f1f1' },
                    backgroundColor: open ? '#f1f1f1' : 'initial'
                }}
            >
                <TableCell>
                    <Checkbox size='small' disabled={checkDisable} checked={inSels} onChange={() => { handleSelect(row?.id) }} sx={{ p: '2px' }} />
                </TableCell>
                <TableCell>{ page === 1 ? idx + 1 : (page - 1) * pageSize + idx + 1 }</TableCell>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={ handleOpen }
                    >
                        { open ? <KeyboardArrowUpIcon color='secondary' /> : <KeyboardArrowDownIcon color='secondary' /> }
                    </IconButton>
                </TableCell>
                {
                    fieldsInfo.map( col => {
                        return col.field_name === 'status'
                                    ?   <SelectStatusCell row={row} setRow={setRow} />
                                    :   (col.field_name === 'process')
                                            ?   <SelectProcessCell row={row} role={role} setRow={setRow} />
                                            :   col.field_name === 'branch'
                                                    ?   <BranchCell branch={row?.branch} of_customer={Boolean(row.customer)} />
                                                    :   ['delman', 'packer'].includes(col.field_name)
                                                            ?   <SelectStaffCell row={row} refetch={refetch} sels={sels} fieldName={col.field_name} />
                                                            :   col.field_name === 'note'
                                                                    ?   <NoteCell hasNote={row.note} orderId={row.id} />
                                                                    :   col.field_name === 'seller'
                                                                            ?   <SellerCell sellerId={row.seller} />
                                                                            :   col.field_name === 'createdOn'
                                                                                    ?   <CreatedOnCell>{row[col.field_name]}</CreatedOnCell>
                                                                                    :   <TableCell
                                                                                            key={ col.field_name }
                                                                                            width={ cellWidthMap[col.field_name]||'initial' }
                                                                                        >
                                                                                            {
                                                                                                col.field_name === 'totalPrice'
                                                                                                    ?   toPrice(row[col.field_name])
                                                                                                    :   ['qp', 'has_loc'].includes(col.field_name)
                                                                                                            ?   <Checkbox checked={row[col.field_name]} size='small' />
                                                                                                            :   col.field_name === 'payType'
                                                                                                                    ?   PAYMENT_TYPES[row?.payType]||'-'
                                                                                                                    :   row[col.field_name]||''
                                                                                            }
                                                                                        </TableCell>
                    })
                }
            </TableRow>
            {open && <OrderDetail row={row} setRow={setRow} colsLength={colsLength} />}
        </>
    )
}
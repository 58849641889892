import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { getToast } from '../../../auth/helpers/ToastAlert'
import { getTodayShipment } from '../../../../api/shipment'
import { isTokenValid } from '../../../../utils/functions'
import { getDelmanNames } from '../../../../api/staff'
import ShipmentHistory from './ShipmentHistory'
import { Box, Tab, Tabs } from '@mui/material'
import CurrentShipment from './Current'
import { useQuery } from 'react-query'
import '../../../../style.css'

const ShipCtx = createContext()
export const useShipCtx = () => useContext(ShipCtx)

const Shipment = () => {
    const { role } = isTokenValid()
    const isDelman = role === 'D'
    const [tab, setTab] = useState(0)
    const handleChange = (e, v) => {
        if (v === 1) { goToShipmentProgress() }
        else { setTab(v) }
    }

    const [staffs, setStaffs] = useState([])
    const delmanNames = staffs?.map(s => ({ id: s.id, name: s.name }))
    useEffect(() => {
        getDelmanNames()
            .then(res => { if (res?.data) { setStaffs(res.data) } })
            .catch(err => { console.log("staffs ERR: ", err) })
    }, [])

    // msg
    const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
    const alertWarning = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
    const alertSuccess = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }

    // today shipments
    const { data, refetch: refetchShipments, isFetching } = useQuery('get_today_shipment', getTodayShipment, { onError: alertWarning, refetchOnWindowFocus: true })
    const ships = data?.data?.results||[]
    //console.log("D: ", ships)
    
    const tabRef = useRef()
    const tabHeight = tabRef?.current?.getBoundingClientRect()?.height||0

    const noShips = ships?.length === 0
    const noProgress = !ships?.find(s => s.startTime && s.lat && s.lng)
    const goToShipmentProgress = () => {
        if (noShips) {
            setMsg({ m: 'Түгээлт байхгүй!', s: 'warning' })
            return
        } else if (noProgress) {
            setMsg({ m: 'Эхэлсэн түгээлт байхгүй!', s: 'warning' })
            return
        }
        window.open('/admin/shipment_progress', '_blank')
    }

    return (
        <ShipCtx.Provider value={{ isDelman, delmanNames, msg, setMsg, alertSuccess, alertWarning, refetchShipments }}>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
            <Tabs 
                value={tab}
                onChange={handleChange} 
                aria-label='tabs' 
                ref={tabRef}
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'Өнөөдрийн түгээлт'} value={0} />
                {/* <Tab label={'Түгээлтийн явц'} value={1} /> */}
                <Tab label={'Түгээлтийн түүх'} value={2} />
            </Tabs>
            <Box sx={{ height: `calc(100% - ${tabHeight}px)` }}>
                { 
                    tab === 0 && 
                        <CurrentShipment
                            isDelman={isDelman}
                            ships={ships}
                            delmanNames={delmanNames}
                            alertWarning={alertWarning}
                            msg={msg}
                            setMsg={setMsg}
                            isFetching={isFetching}
                            refetchShipments={refetchShipments}
                        />
                }
                { tab === 2 && <ShipmentHistory delmanNames={delmanNames} />}
            </Box>
        </ShipCtx.Provider>
    )
}

export default Shipment

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useEffect, useRef, useState } from 'react'
import { useMap } from '@vis.gl/react-google-maps'
import { getDelmanColor } from '../../../../utils'
import DeliveryStop from './DeliveryStop'

export default function DeliveryStops({ stops, delmanLocs }) {
    const map = useMap()
    const allStopsWithColor = stops?.map(s => ({...s, stops: s?.stops?.map(st => ({ ...st, color: getDelmanColor(s.delmanId) }))}))
    const allStops = allStopsWithColor?.reduce((acc, cur) => ([...acc, ...cur?.stops]), [])||[]
    const stopPoints0 = allStops.reduce((acc, cur) => {
        const exist = acc.find(a => a.branchId === cur.branchId)
        exist ? exist.orders.push(cur.orderNo) : acc.push({ ...cur, orders: [cur.orderNo] })
        return acc
    }, [])
    const stopPoints = stopPoints0.map(s => allStops?.filter(st => st.itemId === s.itemId)?.find(item => item?.process !== 'O') ? { ...s, isDelivered: true } : {  ...s, isDelivered: false })

    // fit bound
    const points = delmanLocs?.length > 0 ? [...stopPoints, ...delmanLocs] : stopPoints
    useEffect(() => {
        if (map && points.length > 0) {
            const bounds = new window.google.maps.LatLngBounds()
            points.forEach((stop) => { bounds.extend(new window.google.maps.LatLng(stop.lat, stop.lng)) })
            map.fitBounds(bounds)
        }
    }, [map, stops])

    // clusterer
    const [markers, setMarkers] = useState({})
    const clusterer = useRef(null)
    useEffect(() => {
        if (!map) return
        if (!clusterer.current) { clusterer.current = new MarkerClusterer({ map }) }
    }, [map])
    useEffect(() => {
        if (!map || !markers) return
        if (clusterer.current) {
            const curItemIds = Object.keys(stopPoints).map(s => stopPoints[s]?.itemId?.toString())||[]
            const newMarkers = Object.fromEntries(Object.entries(markers).filter(([key, value]) => curItemIds.includes(key)))
            clusterer.current.clearMarkers()
            clusterer.current.addMarkers(Object.values(newMarkers))
        }
    }, [markers, stopPoints])

    const setMarkersRef = (marker, key) => {
        if (!marker||!key) return
        setMarkers((prev) => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                let newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    return (
        <>
            {
                stopPoints?.map((s, i) =>(
                    <DeliveryStop 
                        key={`_${s?.itemId}`}
                        stop={s}
                        setMarkersRef={setMarkersRef}
                    />
                ))
            }
        </>
    )
}

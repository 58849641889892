import { AppBar, Box, CssBaseline, Divider, Drawer, Toolbar, Typography } from '@mui/material'
import { primaryMain } from '../../utils/constants'
import { Outlet, useNavigate } from 'react-router'
import LogOut from '../admin/AdminNav/LogOut'
import { useRef, useState } from 'react'
import TabMenu from './TabMenu'

const drawerWidth = 240
const tabs = {
    0: { name: 'Бүртгэл', path: 'main' },
    1: { name: 'Баталгаажуулах код', path: 'otp' },
    2: { name: 'Мэдэгдэл', path: 'notif'},
}

export default function Confirmation() {
    const [selIdx, setSelIdx] = useState(0)
    const navi = useNavigate()
    const handleNavi = (selIdx) => {
        setSelIdx(selIdx)
        navi(tabs[selIdx].path)
    }
    const r = useRef(null)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                ref={r}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <LogOut />
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth, flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                anchor='left'
                variant='permanent'
            >
                <Toolbar>
                    <img
                        src='/Pharmo.png'
                        width='40px'
                        height='40px'
                        style={{ marginRight: '10px' }}
                    />
                    <Typography variant='h6' noWrap component='div' sx={{ color: primaryMain }}>Pharmo</Typography>
                </Toolbar>
                <Divider />
                <TabMenu tabs={tabs} selIdx={selIdx} handleNavi={handleNavi} />
            </Drawer>
            <Box component='main' sx={{ p: 1, width: '100%' }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box> 
    )
}

import { Switch, TableCell, TableRow, Tooltip } from "@mui/material"
import AxiosWithToken from "../../../../../../global/axiosBase"

export function formatDate(dateStr) {
	let date = new Date(dateStr)
	let formattedDatetimeStr = date.toISOString().slice(0, 19).replace("T", " ")
	return formattedDatetimeStr
}

export default function Row(props) {
	const { row, idx, page, pageSize, setMsg, refetch } = props;
	const createdOn = row?.createdOn ? formatDate(row.createdOn) : ''
	const handleCheck = () => {
		AxiosWithToken.patch(`user_invited/${row.id}/`)
			.then(res => {console.log(res); setMsg({ m: 'Амжилттай!', s: 'success' }); refetch() })
			.catch(e => { console.log(e); setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
	}

	return (
		<TableRow key={`${idx + 1}`} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "& > td": { padding: "2px 4px", fontSize: "13px" } }}>
			<TableCell>
				{page === 1 ? idx + 1 : page * pageSize + idx + 1}
			</TableCell>
			<TableCell align="left" sx={{ paddingLeft: '15px !important' }}>{row.name}</TableCell>
			<TableCell align="left">{row.rd}</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="center">{row.phone}</TableCell>
			<TableCell align="center" sx={{ textAlign: "center" }}>
				<Tooltip title={row?.is_bad ? "Баталгаажуулсан" : "Баталгаажуулах"} placement="right">
					<Switch checked={row?.isReviewed} disabled={row?.isReviewed} onChange={handleCheck} size="small" />
				</Tooltip>
			</TableCell>
			<TableCell align="center">{row.referral}</TableCell>
			<TableCell align="center">{row.note}</TableCell>
			<TableCell align="center">{createdOn}</TableCell>
		</TableRow>
	)
}

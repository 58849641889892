import AxiosWithToken, { Axios, baseUrl } from "../global/axiosBase"
import { getPageParams } from "./utils"
import { keys } from "underscore"
import axios from 'axios'

// company
// get company info
export const getCompanyInfo = () => {
    return AxiosWithToken.get(`company/`)
}
/// add company info
export const addCompany = (companyInfo) => {
    return Axios.post('company/', companyInfo)
}
export const updateCompanyLicense = (data) => {
    return Axios.patch('company/update_license/', data)
}
// update company info
export const updateCompany = (companyId, companyInfo) => {
    return AxiosWithToken.patch(`company/${companyId}/`, companyInfo)
}
export const updateCompanyContact = (companyId, data) => {
    return AxiosWithToken.patch(`company/${companyId}/contacts/`, data)
}

// branch
// get branches
export const getBranches = () => {
    return AxiosWithToken.get('branch/')
}
export async function getBranchList() {
    return await AxiosWithToken.get('branch/')
}

// get branch
export const getBranch = (branchId) => { return AxiosWithToken.get(`branch/${branchId}/`) }

// add branch
export const addBranch = (branchInfo) => { return AxiosWithToken.post('branch/', branchInfo) }

// update branch
export const updateBranch = (branchInfo, branchId) => { return AxiosWithToken.patch(`branch/${branchId}/`, branchInfo) }
export const updateBranchInfo = (tk, data) => axios.patch(
    `${baseUrl}update_branch_info/`,
    data,
    { headers: { 'Content-Type': 'application/json', 'Authorization': `Basic ${tk}` } }
)

// remove branch
export const removeBranch = (branchId) => { return AxiosWithToken.delete(`branch/${branchId}/`) }

// get suppliers
export const getSuppliers = () => { return Axios.get('suppliers/') }

// Settings
// get settings
export const getSettings = (userId) => {
    return AxiosWithToken.get(`settings/${userId}/`)
}
// set settings
export const updateSettings = (userId, data) => {
    return AxiosWithToken.patch(`settings/${userId}/`, data)
}

// get partners
export const getPartners = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey
    let qParam = getPageParams(page, pageSize, 'partner/')
    if (orderParam !== 'ordering=') {
        qParam += `?${orderParam}`
    }
    if (filterParam) { qParam += qParam !== 'partner/' ? `&${filterParam}` : `?${filterParam}` }
    //console.log("refetched: ", orderParam, filterParam, qParam)
    return AxiosWithToken.get(qParam)
}

export const getCustomers = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'customer/';
    if (keys(filterParam)?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== '') {
        qParam += `${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}
export const getSuppCustomers = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'supplier/customer/';
    if (filterParam?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== '') {
        qParam += `${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}
export const getInvitedUsers = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'user_invited/';
    if (filterParam?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== '') {
        qParam += `${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}

export const getSupCustTypes = () => { return AxiosWithToken.get('supplier_customer_types/') }

export const getCustomerTypes = () => { return AxiosWithToken.get('cust_type/') }
export const getCustomer = (custId) => AxiosWithToken.patch(`customer/${custId}/`)
export const getCustomerCredit = (custId, data) => AxiosWithToken.patch(`customer/${custId}/`, data)

export const getOrdererCustomers = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'orderer_customer/';
    if (keys(filterParam)?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== '') {
        qParam += `${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}

// get partners
export const getLogs = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'supplier_staff_log/';
    if (keys(filterParam)?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== 'ordering=') {
        qParam += `?${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}

export const getMacsLogs = ({ queryKey }) => {
    const [_, page, pageSize, orderParam, filterParam] = queryKey

    let qParam = 'supplier_macs_log/'
    if (keys(filterParam)?.length) {
        for (let fil of filterParam) {
            let fquery = fil?.k + fil?.o + fil?.v
            qParam += qParam?.endsWith('/') ? `?${fquery}` : qParam.endsWith('?') ? fquery : `&${fquery}`
        }
    }
    if (page) {
        let pgs = `page=${page}`;
        if (filterParam != null && filterParam.length > 0) { pgs = `page=1`; }
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`;
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }

    if (orderParam !== 'ordering=') {
        qParam += `?${orderParam}`
    }
    return AxiosWithToken.get(qParam)
}

export const getPartnerSuppliers = () => {
    return AxiosWithToken.get('suppliers_of_orderer/')
}
// update partner info
export const updatePartner = (id, data) => AxiosWithToken.patch(`partner/${id}/`, data)

// q
export const ci = ({ branchId, note }) => {
    if (branchId || note) { return AxiosWithToken.post('ci/', { branchId, note }) }
    return AxiosWithToken.post('ci/')
}
export const cp = () => AxiosWithToken.get(`cp/`)

export const rq = (data) => AxiosWithToken.post('rq/', { ...data })
export const gq = () => AxiosWithToken.get('rq/')
export const dq = () => AxiosWithToken.delete('rq/')

export const checkSQpay = () => AxiosWithToken.get('check_sqpay/')

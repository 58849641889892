import { COMPANY_TYPES } from '../../../../utils/constants'
import { isTokenValid } from '../../../../utils/functions'
import { getCompanyInfo } from '../../../../api/company'
import { Box, Grid, Typography } from '@mui/material'
import { apiUrl } from '../../../../global/axiosBase'
import { useEffect, useState } from 'react'
import ReadOnlyField from './ReadOnlyField'
import Contacts from './Contacts'
import Userinfo from './UserInfo'

export default function CompanyInfo() {
    const { email } = isTokenValid()
    const [info, setInfo] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const fetchCi = () => {
        setIsFetching(true)
        getCompanyInfo()
            .then(res => { setInfo(res?.data?.[0]) })
            .catch(e => { console.log("Error: ", e) })
            .finally(() => { setIsFetching(false) })
    }
    useEffect(() => { fetchCi() }, [])
    const openOnNewTab = (lnk) => { window.open(lnk, '_blank', 'noopener,noreferrer') }
    const ubr = info?.branches?.find(b => b?.manager?.email === email) // user branch
    const mgr = ubr?.manager||null
    //console.log("RESD: ", info)

    return (
        <Box sx={{ p: 2, pl: 3, pt: 3, width: '600px' }}>
            {!isFetching && <Userinfo name={mgr?.name} phone={mgr?.phone} email={mgr?.email} />}
            <Typography sx={{ fontSize: '15px', color: '#666', mb: 2, mt: 2 }}>Байгууллагын мэдээлэл</Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}><ReadOnlyField label={'Компаний нэр'} value={info?.name} /></Grid>
                <Grid item xs={6}><ReadOnlyField label='Компаний РД' value={info?.rd} /></Grid>
                <Grid item xs={6}><ReadOnlyField label={'Компаний төрөл'} value={COMPANY_TYPES?.[info?.cType]} /></Grid>
                <Grid item xs={6}>
                    <Box>
                        <Typography sx={{ fontSize: '13px', color: '#666' }}>Тусгай зөвшөөрөл: </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {
                            info?.license?.map(
                                (url, index) => 
                                <Box 
                                    sx={{ marginLeft: 1, cursor: 'pointer', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' }}
                                    onClick={() => openOnNewTab(apiUrl + url)}
                                >
                                    <img
                                        alt={`license_${index+1}`}
                                        width={'60px'}
                                        height={'60px'}
                                        src={apiUrl + url}
                                    />
                                </Box>
                            )
                        }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={6}><ReadOnlyField label={'Урилгын код'} value={info?.referral_code} /></Grid>
            {!isFetching && <Contacts email={info?.email} phone={info?.phone} cId={info?.id} refetch={fetchCi} />}
        </Box>
    )
}

import { CheckCircle, LocationOn } from '@mui/icons-material'
import { Box } from '@mui/material'

export default function LocIcon({ stop }) {
    return (
        <Box sx={{ position: 'relative' }}>
            <LocationOn
                sx={{
                    color: stop?.color||'#004EFF',
                    position: 'absolute',
                    bottom: 0,
                    transform: 'translate(-50%)',
                    fontSize: '60px',
                    filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.4))',
                }}
            />
            {
                stop?.isDelivered && 
                <CheckCircle 
                    sx={{
                        position: 'absolute',
                        transform: 'translate(10%, -290%)',
                        color: 'blue'
                    }}
                />
            }
        </Box>
    )
}

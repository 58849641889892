import { InputAdornment, TextField } from '@mui/material'
import Suppliers from './Suppliers'


const labels = {
    name: 'нэрээр',
    intName: 'ерөнхий нэршлээр',
    barcode: 'баркодоор',
}
export default function SearchInput({ params, value, setSearch, searchKey, disabled }) {
    return (
        <TextField
            {...params}
            inputProps={{ ...params.inputProps, style: { padding: 0, fontSize: '14px' } }}
            size='small'
            placeholder={`Барааны ${labels[searchKey]} хайх`}
            variant='outlined'
            sx={{ backgroundColor: 'white' }}
            value={value}
            onChange={e => setSearch(e?.target?.value)}
            InputProps={{
                ...params.InputProps,
                style: { fontSize: '14px' },
                startAdornment: <InputAdornment position="start">
                                    <Suppliers disabled={disabled} />
                                </InputAdornment>,
                endAdornment: null
            }}
            disabled={disabled}
        />
    )
}
